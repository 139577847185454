import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Auth {
  loading: boolean;
}


const initialState: Auth = {
  loading: false,
};

export const authSlice = createSlice({
  name: 'authLoading',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>): void => {
      state.loading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading } = authSlice.actions;
// export const selectLocale = (state:RootState) => state.locale;
export default authSlice.reducer;

import {
  Button,
  Group,
  Image,
  Input,
  LoadingOverlay,
  Modal,
  FileInput,
} from "@mantine/core";
import { useContext, useEffect, useState } from "react";

import {
  useGetPaymentUrlMutation,
  useLazyGetCorporateEmployeesQuery,
  useLazyGetMyWalletQuery,
  useTransferToMultipleEmployeesMutation,
} from "../store/payment.query";

import { Outlet, useLocation, useParams } from "react-router-dom";
import { CollectionQuery, Order } from "../../../models/collection.model";
import { Passenger } from "../../../models/passenger.model";
import AuthContext from "../../../shared/auth/context/authContext";
import Card from "../../../shared/component/Card/card-component";
import ModalCollectionSelector from "../../../shared/component/collection-selector/components/modal-collection-selector";
import { CollectionSelectorConfig } from "../../../shared/component/collection-selector/model/collection-selector-config";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import { Pagination } from "../../../shared/component/pagination/pagination";
import { debounce } from "lodash-es";
import { TextInput, ActionIcon } from "@mantine/core";
import {
  IconDeviceFloppy,
  IconSearch,
  IconUpload,
  IconUser,
  IconUserX,
} from "@tabler/icons";
import {
  EntityConfig,
  entityViewMode,
} from "../../../models/entity-config.model";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useImportEmployeeMutation,
  useRemoveEmployeeMutation,
} from "../../corporate/store/corporate.query";

const schema = yup
  .object()
  .shape({
    users: yup
      .mixed()
      .required("Employee file is required")
      .test("fileSize", "Users file should be less than 2MB", (value) => {
        return value && value?.size <= 2000000;
      })
      .test("type", "Only xlsx formats are accepted", (value) => {
        console.log(value);
        return (
          value &&
          value.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      }),
  })
  .required();

export default function PaymentForEmployee() {
  const params = useParams();
  const location = useLocation();
  const [opened, setOpened] = useState(false);
  const [confirmation, setConfirmation] = useState<{
    modal: boolean;
    id: string;
  }>({ modal: false, id: "" });
  const [amount, setAmount] = useState<number>();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");
  const [importModal, setImportModalOpened] = useState(false);
  const [transferAmount, setTransferAmount] = useState<number>();
  const [amountError, setAmountError] = useState("");
  const [paymentMethod, setPaymentMethod] = useState<"payment" | "transfer">(
    "payment"
  );
  const [singleTransfer, setSingleTransfer] = useState<boolean>(false);
  const [paymentGateWay, setPaymentGateWay] = useState<"chapa" | "telebirr">();
  const [selectedEmployee, setSelectedEmployee] = useState<Passenger>();
  const [transferFromWallet, setTransferFromWallet] = useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });

  const [importEmployee, importResponse] = useImportEmployeeMutation();
  const [getCorporateEmployees, corporateEmployees] =
    useLazyGetCorporateEmployeesQuery();
  const [getPaymentUrl, paymentUrl] = useGetPaymentUrlMutation();
  const [removeEmployee, removeResponse] = useRemoveEmployeeMutation();
  const [getMyWallet, my_wallet] = useLazyGetMyWalletQuery();
  const [transferForMultipleEmployees, transferResponse] =
    useTransferToMultipleEmployeesMutation();

  const collectionSelectorModalConfig: CollectionSelectorConfig = {
    multiple: true,
    size: "100%",
    title: "Employees",
    identity: "id",
    visibleColumn: [
      { key: "name", name: "Name" },
      { key: "email", name: "Email" },
      { key: "phoneNumber", name: "Phone Number" },
    ],
    primaryColumn: { key: "name", name: "Name" },
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onSubmit = (data: any) => {
    const formData = new FormData();
    formData.append("users", data.users);
    importEmployee(formData).then(() => setImportModalOpened(false));
  };

  const onError = (data: any) => {
    console.log(data);
  };

  const transferForMultipleEmployeesFunc = (data: Passenger[]) => {
    const employees = data.map((employee: Passenger) => {
      return employee.id;
    });
    transferForMultipleEmployees({
      amount: transferAmount,
      employees: employees,
    })
      .then((response) => setTransferAmount(undefined))
      .catch((error) => setTransferAmount(undefined));
    if (transferResponse.isSuccess) {
      setTransferFromWallet(false);
    }
  };

  useEffect(() => {
    if (user) {
      getMyWallet();
      getCorporateEmployees(collection);
    }
  }, [collection, user, getCorporateEmployees, getMyWallet]);

  useEffect(() => {
    if (transferAmount) {
      singleTransfer &&
        selectedEmployee &&
        transferForMultipleEmployeesFunc([selectedEmployee]);
    }
  }, [transferAmount]);

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
  }, [setViewMode, params?.id, location]);

  const data: Passenger[] | undefined = corporateEmployees.data?.data;
  const config: EntityConfig<Passenger> = {
    primaryColumn: { key: "name", name: "Name" },
    detailUrl: "detail",
    rootUrl: "/corporate/payment",
    identity: "id",
    name: "name",
    visibleColumn: [
      { key: "name", name: "Name" },
      { key: "email", name: "Email" },
      { key: "phoneNumber", name: "Phone", hideSort: true },
      { key: "gender", name: "Gender", hideSort: true },
      {
        key: ["emergencyContact", "phoneNumber"],
        name: "Emergency Contact",
        render: (user) => {
          return (
            <div className="flex space-x-1">
              <span>{user?.emergencyContact?.name}</span>
              <span>{user?.emergencyContact?.phoneNumber}</span>
            </div>
          );
        },
        hideSort: true,
      },
      {
        key: "id",
        name: "Pay",
        render: (employee) => {
          return (
            <div className="flex justify-start items-center md:invisible md:group-hover:visible">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="fill-current h-4 cursor-pointer hover:text-primary"
                viewBox="0 0 26 26"
                onClick={() => {
                  setOpened(true);
                  setSelectedEmployee(employee);
                  setSingleTransfer(true);
                }}
              >
                <path d="M12.9375 1L11.8125 1.0625L11.71875 1.0625L11.5625 1.09375L11.875 3.0625L11.90625 3.0625L13.0625 3 Z M 9.5 1.5L9.46875 1.53125L9.4375 1.53125L9.375 1.5625L8.375 1.90625L8.3125 1.9375L8.28125 1.96875L7.46875 2.34375L8.34375 4.15625L9.0625 3.78125L9.0625 3.8125L9.96875 3.46875L10.0625 3.4375L9.84375 2.875 Z M 5.625 3.53125L5.40625 3.6875L5.34375 3.71875L5.3125 3.75L4.53125 4.46875L4.46875 4.53125L4.03125 5.03125L5.53125 6.375L5.875 5.9375L5.9375 5.875L6.59375 5.3125L6.65625 5.25L6.84375 5.125 Z M 19 4L20 11L22.0625 8.9375C22.628906 10.175781 23 11.519531 23 13C23 18.566406 18.566406 23 13 23C7.457031 23 3.035156 18.597656 3 13.0625L3 13.03125C3 13.019531 3 13.011719 3 13C3 12.96875 3 12.9375 3 12.90625L3.0625 11.90625L3.1875 11.15625L1.1875 10.875L1.0625 11.71875L1.0625 11.8125L1 12.8125C0.996094 12.855469 0.996094 12.894531 1 12.9375C1 12.957031 1 12.980469 1 13C1 19.636719 6.363281 25 13 25C19.636719 25 25 19.636719 25 13C25 10.925781 24.457031 9.050781 23.59375 7.40625L26 5 Z M 12.3125 5.6875L12.3125 7.40625C10.511719 7.707031 9.40625 9 9.40625 10.5C9.40625 12.101563 10.601563 12.988281 12.5 13.6875C13.898438 14.1875 14.5 14.699219 14.5 15.5C14.5 16.300781 13.792969 16.90625 12.59375 16.90625C11.492188 16.90625 10.511719 16.492188 9.8125 16.09375L9.3125 17.8125C9.914063 18.210938 11.085938 18.59375 12.1875 18.59375L12.1875 20.59375L13.8125 20.59375L13.8125 18.6875C15.710938 18.386719 16.8125 17 16.8125 15.5C16.8125 13.898438 15.90625 12.988281 13.90625 12.1875C12.304688 11.585938 11.6875 11.105469 11.6875 10.40625C11.6875 9.707031 12.105469 9.09375 13.40625 9.09375C14.605469 9.09375 15.414063 9.488281 15.8125 9.6875L16.3125 8C15.710938 7.699219 14.914063 7.40625 13.8125 7.40625L13.8125 5.6875 Z M 2.75 6.78125L2.46875 7.21875L2.4375 7.28125L2.40625 7.3125L1.96875 8.28125L1.9375 8.3125L1.90625 8.375L1.78125 8.75L3.65625 9.4375L3.8125 9.0625L3.78125 9.0625L4.21875 8.1875L4.4375 7.8125Z" />
              </svg>
            </div>
          );
        },
        hideSort: true,
      },
      {
        key: "id",
        name: "Action",
        hideSort: true,
        render: (passenger) => {
          return (
            <div className="flex items-center justify-center group-hover:visible invisible">
              <ActionIcon
                size={"sm"}
                radius={"md"}
                color={"red"}
                loading={removeResponse.isLoading}
                onClick={() =>
                  setConfirmation({ modal: true, id: passenger.id })
                }
              >
                <IconUserX size={16} />
              </ActionIcon>
            </div>
          );
        },
      },
    ],
  };

  return location.pathname !== "/corporate/payment/deposit" ? (
    <div className="mt-12 bg-white">
      <div className={"min-w-max"}>
        <div className="relative overflow-x-auto relative">
          {my_wallet.isSuccess && (
            <div className="w-full h-14 flex items-center justify-between px-2 bg-gradient-to-r from-primary to-orange-300 ">
              <div className="h-full w-full items-center justify-start flex px-4">
                <Image
                  className="text-gray-700"
                  height={30}
                  width={100}
                  radius="md"
                  src="/kabba-logo.png"
                  alt="logo"
                />
              </div>
              <div className="flex items-center dark:text-gray-700  font-semibold">
                <span className="text-sm items-center ">{`Balance: `}</span>
                <span className="text-sm items-center">
                  {new Intl.NumberFormat().format(
                    my_wallet.data.balance?.toFixed(2)
                  )}
                  ETB
                </span>
              </div>
            </div>
          )}
          <div className="w-full flex justify-end space-x-2 items-center mt-2 py-2">
            <div className="flex space-x-2 px-2">
              <Button
                onClick={() => setImportModalOpened(true)}
                size="xs"
                className="bg-btn_primary"
              >
                <IconUser size={14} /> Import
              </Button>
              <Button
                size="xs"
                className="bg-btn_primary flex space-x-1 text-white"
                onClick={() => {
                  setPaymentMethod("transfer");
                  setSingleTransfer(false);
                  setOpened(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-current h-4"
                  viewBox="0 0 26 26"
                >
                  <path d="M12.9375 1L11.8125 1.0625L11.71875 1.0625L11.5625 1.09375L11.875 3.0625L11.90625 3.0625L13.0625 3 Z M 9.5 1.5L9.46875 1.53125L9.4375 1.53125L9.375 1.5625L8.375 1.90625L8.3125 1.9375L8.28125 1.96875L7.46875 2.34375L8.34375 4.15625L9.0625 3.78125L9.0625 3.8125L9.96875 3.46875L10.0625 3.4375L9.84375 2.875 Z M 5.625 3.53125L5.40625 3.6875L5.34375 3.71875L5.3125 3.75L4.53125 4.46875L4.46875 4.53125L4.03125 5.03125L5.53125 6.375L5.875 5.9375L5.9375 5.875L6.59375 5.3125L6.65625 5.25L6.84375 5.125 Z M 19 4L20 11L22.0625 8.9375C22.628906 10.175781 23 11.519531 23 13C23 18.566406 18.566406 23 13 23C7.457031 23 3.035156 18.597656 3 13.0625L3 13.03125C3 13.019531 3 13.011719 3 13C3 12.96875 3 12.9375 3 12.90625L3.0625 11.90625L3.1875 11.15625L1.1875 10.875L1.0625 11.71875L1.0625 11.8125L1 12.8125C0.996094 12.855469 0.996094 12.894531 1 12.9375C1 12.957031 1 12.980469 1 13C1 19.636719 6.363281 25 13 25C19.636719 25 25 19.636719 25 13C25 10.925781 24.457031 9.050781 23.59375 7.40625L26 5 Z M 12.3125 5.6875L12.3125 7.40625C10.511719 7.707031 9.40625 9 9.40625 10.5C9.40625 12.101563 10.601563 12.988281 12.5 13.6875C13.898438 14.1875 14.5 14.699219 14.5 15.5C14.5 16.300781 13.792969 16.90625 12.59375 16.90625C11.492188 16.90625 10.511719 16.492188 9.8125 16.09375L9.3125 17.8125C9.914063 18.210938 11.085938 18.59375 12.1875 18.59375L12.1875 20.59375L13.8125 20.59375L13.8125 18.6875C15.710938 18.386719 16.8125 17 16.8125 15.5C16.8125 13.898438 15.90625 12.988281 13.90625 12.1875C12.304688 11.585938 11.6875 11.105469 11.6875 10.40625C11.6875 9.707031 12.105469 9.09375 13.40625 9.09375C14.605469 9.09375 15.414063 9.488281 15.8125 9.6875L16.3125 8C15.710938 7.699219 14.914063 7.40625 13.8125 7.40625L13.8125 5.6875 Z M 2.75 6.78125L2.46875 7.21875L2.4375 7.28125L2.40625 7.3125L1.96875 8.28125L1.9375 8.3125L1.90625 8.375L1.78125 8.75L3.65625 9.4375L3.8125 9.0625L3.78125 9.0625L4.21875 8.1875L4.4375 7.8125Z" />
                </svg>
                <span>Transfer from wallet</span>
              </Button>
            </div>
          </div>
          <EntityList
            viewMode={viewMode}
            showNewButton={false}
            title={"Employees"}
            total={corporateEmployees?.data?.count}
            itemsLoading={
              corporateEmployees.isLoading ||
              corporateEmployees.isFetching ||
              transferResponse.isLoading
            }
            config={config}
            items={data}
            initialPage={1}
            defaultPageSize={10}
            pageSize={[10, 15, 20]}
            collectionQuery={collection}
            onPaginationChange={(skip: number, top: number) => {
              setCollection({ ...collection, skip: skip, top: top });
            }}
            onSearch={(data: any) => {
              setCollection({
                ...collection,
                search: data,
                searchFrom: ["name", "email", "phoneNumber"],
              });
            }}
            onFilterChange={(data: any) =>
              setCollection({ ...collection, filter: data })
            }
            onOrder={(data: Order) =>
              setCollection({ ...collection, orderBy: [data] })
            }
          />
        </div>
      </div>
      {/* collection selector for multiple employees payment */}
      <ModalCollectionSelector
        config={collectionSelectorModalConfig}
        hasSort={true}
        total={corporateEmployees.data?.count}
        items={corporateEmployees.data?.data}
        itemsLoading={
          corporateEmployees.isLoading || transferResponse.isLoading
        }
        onPaginationChange={(skip: number, top: number) =>
          setCollection({ ...collection, skip: skip, top: top })
        }
        onDone={(data) => {
          transferForMultipleEmployeesFunc(data);
          setPaymentMethod("payment");
          setCollection({ skip: 0, top: 10 });
        }}
        setModalOpened={(data) => {
          setTransferFromWallet(data);
          setPaymentMethod("payment");
        }}
        modalOpened={transferFromWallet}
        search={debounce((key) => {
          setCollection({
            ...collection,
            search: key,
            searchFrom: ["name", "email", "phoneNumber"],
          });
        }, 1000)}
      />
      {/* Amount Modal */}
      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false);
          setAmountError("");
          setPaymentMethod("payment");
          setAmount(undefined);
          setSelectedEmployee(undefined);
          setSingleTransfer(false);
        }}
        size="md"
        title={<span className="font-semibold">Payment</span>}
        centered
      >
        <LoadingOverlay
          visible={paymentUrl.isLoading || transferResponse.isLoading}
        />
        <Input.Wrapper
          id="input-demo"
          withAsterisk
          label="Amount"
          description="Please enter the amount"
          error={amountError !== "" && amountError}
        >
          <Input
            type={"number"}
            required
            onChange={(event: any) => {
              setAmount(event.target.value);
              setAmountError("");
            }}
            id="input-demo"
            placeholder="Put amount here"
          />
        </Input.Wrapper>
        {paymentMethod === "payment" && !singleTransfer && (
          <div className="flex-col space-y-2 p-2">
            <div className="text-xs">Choose Payment method:</div>
            <div className="flex space-x-2 items-center">
              <div
                className={`h-full rounded border p-2 cursor-pointer hover:bg-secondary ${
                  paymentGateWay === "telebirr" && "bg-secondary"
                } w-full items-center justify-start flex px-4`}
                onClick={() => setPaymentGateWay("telebirr")}
              >
                <Image
                  className="text-gray-700"
                  height={40}
                  width={100}
                  radius="md"
                  src="/ktelebirr-logo.png"
                  alt="logo"
                />
              </div>
              <div
                className={`h-full rounded border p-2 cursor-pointer hover:bg-secondary ${
                  paymentGateWay === "chapa" && "bg-secondary"
                } w-full items-center justify-start flex px-4`}
                onClick={() => setPaymentGateWay("chapa")}
              >
                <Image
                  className="text-gray-700"
                  height={40}
                  width={100}
                  radius="md"
                  src="/chapa-logo.png"
                  alt="logo"
                />
              </div>
            </div>
          </div>
        )}
        <Group position="right" mt="xl">
          <Button
            size="xs"
            className="text-xs text-white bg-primary"
            variant="default"
            onClick={() => {
              if (amount === undefined) {
                setAmountError("Please give us payment amount");
              } else {
                setAmountError("");
                setOpened(false);
                // if (paymentMethod === "payment" && !singleTransfer) {
                //   getBill({
                //     amount: amount,
                //     passengerId: selectedEmployee?.id,
                //     returnUrl: `${window?.location?.href}?success=true`,
                //   });
                // } else
                if (singleTransfer) {
                  setTransferAmount(amount);
                  setOpened(false);
                  setAmount(undefined);
                } else {
                  setTransferAmount(amount);
                  setTransferFromWallet(true);
                  setAmount(undefined);
                }
              }
            }}
          >
            {paymentMethod === "payment" ? "Transfer" : "Select employees"}
          </Button>
        </Group>
      </Modal>
      {/* Employee Import Modal */}
      <Modal
        size={"lg"}
        opened={importModal}
        onClose={() => setImportModalOpened(false)}
        title="Import Employees"
      >
        <form onSubmit={handleSubmit(onSubmit, onError)} className="relative">
          <LoadingOverlay visible={importResponse.isLoading} />
          <div className="w-full">
            <Controller
              control={control}
              name="users"
              render={({ field }) => (
                <FileInput
                  withAsterisk
                  error={errors.users && `${errors.users?.message}`}
                  label="Employees"
                  placeholder="upload xlsx"
                  icon={<IconUpload size={14} />}
                  {...field}
                />
              )}
            />
          </div>
          <Group position="right" mt="xl">
            <>
              <Button
                classNames={{ label: "flex space-x-1 " }}
                variant="default"
                className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                type="reset"
                size="xs"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="fill-current h-6"
                    viewBox="0 0 32 32"
                  >
                    <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                  </svg>
                </span>
                <span>Reset</span>
              </Button>
              <Button
                variant="filled"
                className="bg-primary text-white"
                type="submit"
                classNames={{ label: "flex space-x-1" }}
                size={"xs"}
                loading={importResponse.isLoading}
              >
                <span>
                  <IconDeviceFloppy size={22} strokeWidth={1.5} />
                </span>
                <span>Submit</span>
              </Button>
            </>
          </Group>
        </form>
      </Modal>
      {/* Remove employee confirmation */}
      <Modal
        opened={confirmation.modal}
        onClose={() => setConfirmation({ modal: false, id: "" })}
        title={"Remove Employee"}
      >
        <div className="flex items-center justify-center">
          <p>Are you sure doo you want to remove?</p>
        </div>
        <Group mt="xl" position={'right'}>
          <Button
            size="xs"
            variant="outline"
            onClick={() => setConfirmation({ modal: false, id: "" })}
          >
            No
          </Button>
          <Button
            size="xs"
            variant="default"
            className="bg-danger text-white"
            loading={removeResponse?.isLoading}
            onClick={() =>
              removeEmployee({ corporateId: user?.id, id: confirmation?.id }).then(()=>setConfirmation({modal:false,id:''}))
            }
          >
            Yes
          </Button>
        </Group>
      </Modal>
    </div>
  ) : (
    <Outlet />
  );
}

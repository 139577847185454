import { IconUsers } from '@tabler/icons';
import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";
import AuthContext from '../../shared/auth/context/authContext';
import Footer from '../component/footer';
import Header from "../component/header.component";
import MobileFooter from '../component/mobile-footer';


export default function Dashboard() {
  const [searchParams]=useSearchParams();
  const params = useParams();
  const [collapse, setCollapse] = useState<boolean>(false);
  const { user,authenticated, logOut } = useContext(AuthContext);
  const navigate = useNavigate();
 
  useEffect(()=>{

if(user?.type==='corporate'){
 navigate('/corporate')
}
  },[user])
  return (

    <div className="flex h-full relative bg-slate-100">
      
      <div className={`h-full w-full bg-slate-100  overflow-y-auto fixed duration-500 `} >
        <Header  />  
        <div className="w-full items-center justify-center min-h-screen mb-20 md:mb-0">
          <Outlet />
        </div>
       {!authenticated &&   <Footer/>}
       <MobileFooter/>
      </div>
    </div>
  );
}

import { BaseQueryFn } from "@reduxjs/toolkit/query";
import axios, { AxiosError, AxiosRequestConfig } from "axios";

const refreshToken = async () => {
  const config: AxiosRequestConfig = {
    url: `${process.env.REACT_APP_API}/auth/refresh`,
    method: "post",
    headers: {
      "x-refresh-token": (await localStorage.refreshToken)
        ? localStorage.refreshToken
        : "",
    },
  };
  try {
    const { data } = await axios(config);
    localStorage.setItem("accessToken", data?.accessToken);
  } catch (error) {
    const err = error as AxiosError;
    if (err.response?.status === 403) {
      window.location.href = `${window.location.origin}/login`;
    }
  }
};

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      headers?: AxiosRequestConfig["headers"];
      params?: AxiosRequestConfig["params"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {
      const config: AxiosRequestConfig = {
        url: baseUrl + url,
        method: method,
        data: data,
        params: params,
        headers: {
          Authorization: `Bearer ${
            localStorage.accessToken ? await localStorage.accessToken : ""
          }`,
        },
      };

      const result = await axios(config);
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      if (err.response?.status === 401) {
        // try to get a new token
        await refreshToken();
        const result = await axios({
          ...err.config,
          headers: {
            Authorization: `Bearer ${await localStorage.accessToken}`,
          },
        });
        return { data: result.data };
      }
      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  };

import { MantineProvider } from "@mantine/core";
import { NavigationProgress } from "@mantine/nprogress";
import { useSelector } from "react-redux";
import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import "./App.css";
import Dashboard from "./layout/page/dashboard.page";

import Login from "./shared/auth/component/login/login-component";
import { AuthContextProvider } from "./shared/auth/context/authContext";
import NotFound from "./shared/component/404-not-found/404-not-found";
import { RootState } from "./store/app.store";
import React from "react";
import { HomeRoute } from "./feature/home/route/home.route";
import { CorporateRoute } from "./feature/corporate/route/corporate.route";
import { PaymentRoute } from "./feature/payment/route/payment.route";
import { AccountRoute } from "./feature/account/route/account.route";


function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <AuthContextProvider>
          <Dashboard />
        </AuthContextProvider>
      ),

      children: [
        { path: "*", element: <NotFound /> },
        HomeRoute,
        CorporateRoute,
        
      ],
    },
    AccountRoute,
    {
      path: "/login",
      element: (
        <AuthContextProvider>
          <Login />
        </AuthContextProvider>
      ),
    },
    { path: "*", element: <NotFound /> },
  ]);
  const themeClass = useSelector(
    (state: RootState) => state.themeReducer.class
  );
  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
  
    >
     
        <NavigationProgress />
        <div className="bg-slate-100">
          <RouterProvider router={router} />
        </div>
     
    </MantineProvider>
  );
}

export default App;

import {
  Button,
  Group,
  Image,
  Input,
  LoadingOverlay,
  Modal,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useDepositWithChapaMutation,
  useInitiateTelebirrMutation,
} from "../store/payment.query";
export default function Deposit() {
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);
  const [amount, setAmount] = useState<number>();
  const [amountError, setAmountError] = useState("");
  const [paymentGateWay, setPaymentGateWay] = useState<"chapa" | "telebirr">();
  const [depositWithChapa, depositResponse] = useDepositWithChapaMutation();
  const [initiateTelebirr, telebirrResponse] = useInitiateTelebirrMutation();
  const getBill = (data: any) => {
    if (paymentGateWay === "chapa") {
      depositWithChapa(data).then((response: any) => {
        console.log(response);
        if (typeof window !== "undefined" && response.data !== undefined) {
          window.self.location =
            response?.data?.paymentInfo?.data?.checkout_url;
        }
      });
    } else {
      initiateTelebirr(data).then((response: any) => {
        console.log(response);
        if (typeof window !== "undefined" && response.data !== undefined) {
          window.self.location = response?.data?.data.toPayUrl;
        }
      });
    }
  };

  useEffect(() => {
    setOpened(true);
  }, []);
  return (
    <>
      <Modal
        opened={opened}
        classNames={{root:'border border-orange-500'}}
        onClose={() => {
          navigate("/corporate/payment");
          setAmountError("");
        }}
        size="md"
        title={<span className="font-semibold">Deposit</span>}
        centered
      >
        <LoadingOverlay visible={depositResponse.isLoading || telebirrResponse.isLoading} />
        <Input.Wrapper
          id="input-demo"
          withAsterisk
          label="Amount"
          description="Please enter the amount"
          error={amountError !== "" && amountError}
        >
          <Input
            type={"number"}
            required
            onChange={(event: any) => setAmount(event.target.value)}
            id="input-demo"
            placeholder="Put amount here"
          />
        </Input.Wrapper>
        <div className="flex-col space-y-2 p-2">
          <div className="text-xs">Choose Payment method:</div>
          <div className="flex space-x-2 items-center">
            <div
              className={`h-full rounded border p-2 cursor-pointer hover:bg-orange-600 ${
                paymentGateWay === "telebirr" && "bg-orange-500"
              } w-full items-center justify-start flex px-4`}
              onClick={() => setPaymentGateWay("telebirr")}
            >
              <Image
                className="text-gray-700"
                height={40}
                width={100}
                radius="md"
                src="/ktelebirr-logo.png"
                alt="logo"
              />
            </div>
            <div
              className={`h-full rounded border p-2 cursor-pointer hover:bg-orange-600 ${
                paymentGateWay === "chapa" && "bg-orange-500"
              } w-full items-center justify-start flex px-4`}
              onClick={() => setPaymentGateWay("chapa")}
            >
              <Image
                className="text-gray-700"
                height={40}
                width={100}
                radius="md"
                src="/chapa-logo.png"
                alt="logo"
              />
            </div>
          </div>
        </div>
        <Group position="right" mt="xl">
          <Button
            size="xs"
            className="text-xs text-white bg-btn_primary"
            variant="default"
            onClick={() => {
              if (amount === undefined) {
                setAmountError("Please give us payment amount");
              } else {
                setAmountError("");
                getBill({
                  amount: amount,
                  returnUrl: `${window.location?.origin}/corporate/transactions`,
                });
              }
            }}
          >
            Deposit
          </Button>
        </Group>
      </Modal>
    </>
  );
}

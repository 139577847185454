import { Button } from "@mantine/core";
import { IconPhoneCall } from "@tabler/icons";
import { useEffect, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { CollectionQuery, Order } from "../../../models/collection.model";
import Slider from "react-slick";
import { useLazyGetServiceQuery } from "../store/home.query";
import { ServiceCard } from "../component/service-card-component";

export default function HomePage() {

  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });

  const [getServices, services] = useLazyGetServiceQuery();
  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    getServices(collection, true);
  }, [collection]);

  return (
    <>
      <div className="min-h-screen w-full bg-white mb-24">
        <div className="h-70 relative hidden md:block">
          <div className="absolute  -bottom-16 left-24 bg-white h-72 w-96 flex space-y-4 rounded flex-col justify-center items-center shadow-xl pb-4">
            <div className="px-2 text-center text-lg text-slate-600">
              Transform your daily commute with Kabba Comfortable and affordable
              shared commute
            </div>
            <div className="flex justify-center text-primary  text-2xl font-semibold">
              Get mobile apps on
            </div>
            <div className="grid grid-cols-1 gap-4 px-4 md:grid-cols-2 justify-items-center">
              <div>
                <img
                  src="/footer-google-play.svg"
                  className="w-24"
                  alt="google play logo"
                />
              </div>
              <div>
                <img
                  src="/footer-ios-store.svg"
                  className="w-24"
                  alt="ios store logo"
                />
              </div>
            </div>
          </div>
          <img className="w-full" src="/taxi-bg.jpg" alt="img" />
        </div>
        <div className="flex  bg-white flex-col items-center justify-center px-2 md:px-10 pt-24">
          <div className="text-xl md:text-4xl font-bold">Our services</div>
          <div className="md:text-lg font-thin text-center text-slate-600">
            Register yourself and take the amazing benefit of the Apps. Drivers
            can register and earn for the Rides. Users can register and book the
            Rides.
          </div>
        </div>
        <div className="pt-8 mb-4 pb-4 w-full bg-white md:px-20">
          <Slider {...settings}>
            {services.data?.data?.map((service) => (
              <div className="px-6 w-70 h-70 cursor-pointer">
                <ServiceCard
                action={false}
                className="shadow-lg"
                  title={service.title}
                  description={service.description}
                  image={
                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1Z260wWyaun1BUMDVj5j7iTV2WQUHJJTkjg&usqp=CAU"
                  }
                />
              </div>
            ))}
          </Slider>
        </div>
        <div className="pt-20 pb-4 bg-white">
          <div
            className="font-bold flex justify-center items-center text-2xl md:text-4xl"
            style={{ fontFamily: "Roboto" }}
          >
            How it works?
          </div>
          <div
            style={{ fontFamily: "Roboto" }}
            className="flex justify-center items-center text-gray-600 md:text-lg font-thin"
          >
            User Friendly System that will allow you to Book Services with just
            few Steps and Simple Flow.
          </div>
        </div>
        <div className="mt-6 px-4 mb-6 grid justify-items-center gap-4  grid-cols-1 md:grid-cols-3 lg:grid-cols-4 ">
          <div className="h-80 cursor-pointer p-4 flex-col w-72 bg-white space-y-4 hover:shadow-orange-400 shadow-2xl">
            <div className="flex w-full justify-center">
              <img src="/tab-one.png" alt="img" />
            </div>
            <div
              style={{ fontFamily: "Roboto" }}
              className="flex w-full justify-center text-xl md:text-2xl text-primary font-bold"
            >
              Tap to book one
            </div>
            <div
              style={{ fontFamily: "Roboto" }}
              className="flex justify-center px-2 text-center items-center text-gray-600  font-thin"
            >
              Just pick up your phone and put in where you want to go. The app
              will get you are a driver in no time
            </div>
          </div>
          <div className="h-80 cursor-pointer p-4 flex-col w-72 bg-white space-y-4 hover:shadow-orange-400 shadow-2xl">
            <div className="flex w-full justify-center">
              <img src="/get-drive.png" alt="img" />
            </div>
            <div
              style={{ fontFamily: "Roboto" }}
              className="flex w-full justify-center text-xl md:text-2xl text-primary font-bold"
            >
              Get Driver Details
            </div>
            <div
              style={{ fontFamily: "Roboto" }}
              className="flex justify-center px-2 text-center items-center text-gray-600  font-thin"
            >
              Once your Ride request is accepted, the app automatically informs
              you of all the details of the driver including make and model of
              the car.
            </div>
          </div>
          <div className="h-80 cursor-pointer p-4 flex-col w-72 bg-white space-y-4 hover:shadow-orange-400 shadow-2xl">
            <div className="flex w-full justify-center">
              <img src="/route-img.png" alt="img" />
            </div>
            <div
              style={{ fontFamily: "Roboto" }}
              className="flex w-full justify-center text-xl md:text-2xl text-primary font-bold"
            >
              Track and Guide
            </div>
            <div
              style={{ fontFamily: "Roboto" }}
              className="flex justify-center px-2 text-center items-center text-gray-600  font-thin"
            >
              Take a look at the route the driver is taking to reach you. Trace
              his exact location and guide him if necessary.
            </div>
          </div>
          <div className="h-80 cursor-pointer p-4 flex-col w-72 bg-white space-y-4 hover:shadow-orange-400 shadow-2xl">
            <div className="flex w-full justify-center">
              <img src="/hope-img.png" alt="img" />
            </div>
            <div
              style={{ fontFamily: "Roboto" }}
              className="flex w-full justify-center text-xl md:text-2xl text-primary font-bold"
            >
              Hop in and ride on
            </div>
            <div
              style={{ fontFamily: "Roboto" }}
              className="flex justify-center px-2 text-center items-center text-gray-600  font-thin"
            >
              Once the driver reaches you, just hop in and enjoy the trip. The
              app can take your comments and driver ratings.
            </div>
          </div>
        </div>
        <div>
          <div className="mt-8 bg-white w-full flex  h-96 px-10">
            <div className="h-full w-3/4 flex justify-center">
              <img src="/kabba-taxis.png" className="h-96 " alt="img" />
            </div>
            <div className="flex flex-col items-center text-center justify-center text-lg">
              <p className="text-3xl font-extrabold">
                All of our routes displayed at a glance
              </p>
              <p className="text-slate-600" style={{ fontFamily: "Roboto" }}>
                {" "}
                We are bringing all our routes to you. Simply search for a route
                along your way and book a ride.
              </p>
            </div>
          </div>
          <div className="bg-white w-full flex  h-96 px-10 py-2 mb-2">
            <div
              className="flex flex-col items-center justify-center text-lg"
              style={{ fontFamily: "Roboto" }}
            >
              <p className="text-3xl font-extrabold">Corporate Rides</p>
              <p className="text-slate-600" style={{ fontFamily: "Roboto" }}>
                {" "}
                Use this Feature, you can book Rides and let your registered
                company pay for your commercial Rides.
              </p>
            </div>
            <div className="h-full w-3/4 flex justify-center">
              <img src="/corporate-ride.jpg" className="h-84 " alt="img" />
            </div>
          </div>
        </div>
        <div className=" pt-8 w-full bg-slate-100">
          <div className="flex justify-center">
            <h2 className="flex text-3xl font-bold">Safety & Security</h2>
          </div>
          <div>
            <p className="text-center mt-2 text-xl text-slate-600 px-4">
              Our drivers hold a legal license and are fully trained which means
              that every time you take a ride with us you can be assured of 100%
              security & 100% safety. We understand that your safety is the
              biggest concern and we take it very seriously. By providing you a
              safe way to travel, we make sure that you can trust us for your
              trip as much as possible.
            </p>
          </div>
          <div className="grid grid-cols-1 gap-8 md:px-10 justify-items-center mt-8 mb-8 md:grid-cols-2 w-full">
            <div className="w-[608px] space-y-4">
              <img src="/passenger-img.jpg" alt="passenger" />
              <p className="text-primary text-xl font-black">
                Tracking at Each Step
              </p>
              <p className="text-left" style={{ fontFamily: "Roboto" }}>
                We are committed to helping to create a Safe & Secure Trip for
                our users. You can track the entire Trip using your App. In Case
                of Emergency, you can press the Emergency button.
              </p>
            </div>
            <div className="w-[608px] space-y-4">
              <img src="/covid-safety.jpg" className="" alt="passenger" />
              <div className="text-primary text-xl font-black">
                Covid Safety
              </div>
              <p className="text-left" style={{ fontFamily: "Roboto" }}>
                The transport is configured with major Covid Safety Features
                like limited number of Passengers in one Taxi, Mask Verification
                for Drivers, Ratings based on the Safety precautions followed by
                Driver and Safety Checklist for both Riders & Drivers
              </p>
            </div>
          </div>
        </div>
  
        <div className="flex  bg-white flex-col items-center justify-center px-10 pt-8">
          <div className="text-4xl font-bold">Easy Registration</div>
          <div className="text-lg font-thin text-slate-600">
            Register yourself and take the amazing benefit of the Apps. Drivers
            can register and earn for the Rides. Users can register and book the
            Rides.
          </div>
        </div>
        <div className="space-y-2 md:flex md:justify-center md:space-x-16 w-full justify-items-center pt-8 px-12 pb-6">
          <div className="shadow-xl border-radius">
            <img
              src="/kabba-rider.jpg"
              alt="rider img"
              className="w-96 overflow-hidden"
            />
            <div className="footer-div relative h-36 flex items-center justify-center">
              <NavLink
                to={"/driver"}
                className={
                  "flex flex-col items-center justify-center space-y-2"
                }
              >
                <span className="text-primary font-extrabold text-3xl">
                  Passenger
                </span>
                <span> Register to book a Taxi in few taps.</span>
              </NavLink>
            </div>
          </div>
          <div className="shadow-xl border-radius">
            <img
              src="/corporate-bus.jpg"
              alt="rider img"
              className="w-96 overflow-hidden"
            />
            <div className="footer-div relative h-36 flex items-center justify-center">
              <NavLink
                to={"/driver"}
                className={
                  "flex flex-col items-center justify-center space-y-2"
                }
              >
                <span className="text-primary font-extrabold text-3xl">
                  Corporate
                </span>
                <span> Register to book a Taxi in few taps.</span>
              </NavLink>
            </div>
          </div>
        </div>
        
      </div>
    </>
  );
}

import { useContext, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import AuthContext from "../context/authContext";

interface Props {
  children: any;
  role?: string[];
}
function AuthGuard(props: Props) {
  const { children, role } = props;
  const { authenticated, user, logOut } = useContext(AuthContext);
  const navigate = useNavigate();

useEffect(()=>{
async function check(){
  if(await localStorage.userInfo===undefined){
      navigate('/')
  }
}
check();
},[authenticated,navigate])
return authenticated && children
}

export default AuthGuard;

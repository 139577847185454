import { Accordion } from "@mantine/core";
import { IconCheck, IconRefreshDot, IconX } from "@tabler/icons";
import dateFormat from "dateformat";
interface Props {
  routeName?: string;
  status?: string;
  pickupName?: string;
  destinationName?: string;
  startingAt?: string;
  completedAt?: string;
  fee: number;
  vehiclePlateNumber: string;
}
export default function EmployeeBookingHistoryCard(props: Props) {
  const {
    routeName,
    status,
    pickupName,
    destinationName,
    startingAt,
    completedAt,
    fee,
    vehiclePlateNumber,
  } = props;

  return (
    <>
      <Accordion
        defaultValue={routeName}
        className={`w-full border rounded-lg shadow  ${
          status?.toLowerCase() === "cancelled" && "border-red-500"
        } ${status?.toLowerCase() === "completed" && "border-green-500"} ${
          status?.toLowerCase() === "pending" && "border-yellow-500"
        }`}
        classNames={{
          control: "border-0 border-b",
          icon: `${status?.toLowerCase() === "cancelled" && "text-red-500"} ${
            status?.toLowerCase() === "completed" && "text-green-500"
          } ${status?.toLowerCase() === "pending" && "text-yellow-500"}`,
        }}
      >
        <Accordion.Item value={routeName ? routeName : ""}>
          <Accordion.Control
            icon={
              <div className="flex border rounded-full p-2">
                {status?.toLowerCase() === "cancelled" && <IconX size={14} />}
                {status?.toLowerCase() === "completed" && (
                  <IconCheck size={14} />
                )}{" "}
                {status?.toLowerCase() === "pending" && (
                  <IconRefreshDot size={14} />
                )}
              </div>
            }
          >
            <div className="flex w-full justify-between">
              <div>{routeName}</div>
              <div
                className={`${
                  status?.toLowerCase() === "cancelled" && "text-red-500"
                } ${
                  status?.toLowerCase() === "completed" && "text-green-500"
                } ${status?.toLowerCase() === "pending" && "text-yellow-500"}`}
              >
                {status}
              </div>
            </div>
          </Accordion.Control>
          <Accordion.Panel>
            <div className="flex justify-between">
              <div className="flex-col space-y-2">
                <div className="flex space-x-2 items-center">
                  <span>Pickup Name:</span>
                  <span className="text-sm text-gray-500">{pickupName}</span>
                </div>
                <div className="flex space-x-2 items-center">
                  <div>Destination Name:</div>
                  <div className="text-sm text-gray-500">{destinationName}</div>
                </div>
                <div className="flex space-x-2 items-center">
                  <div>Fee:</div>
                  <div className="text-sm text-gray-500">{fee}ETB</div>
                </div>
              </div>
              <div className="flex-col space-y-2">
                <div className="flex space-x-2 items-center">
                  <div>Started At:</div>
                  <div className="text-sm text-gray-500">
                    {dateFormat(startingAt, "mmmm dS, yyyy hh:mm TT")}
                  </div>
                </div>
                <div className="flex space-x-2 items-center">
                  <div>Completed At:</div>
                  <div className="text-sm text-gray-500">
                    {dateFormat(completedAt, "mmmm dS, yyyy hh:mm TT")}
                  </div>
                </div>
                <div className="flex space-x-2 items-center">
                  <div>Vehicle Plate Number:</div>
                  <div className="text-sm text-gray-500">
                    {vehiclePlateNumber}
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </>
  );
}

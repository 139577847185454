/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { Corporate } from "../../../models/corporate.model";
import { Passenger } from "../../../models/passenger.model";
import { Transaction } from "../../../models/transaction.model";
import { Wallet } from "../../../models/wallet.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { CORPORATE_ENDPOINT } from "../corporate.endpoint";

let corporateEmployeeCollection: CollectionQuery;
let archivedEmployeeCollection: CollectionQuery;

const corporateQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getCorporateEmployees: builder.query<
      Collection<Passenger>,
      CollectionQuery
    >({
      query: (collection) => ({
        url: `${CORPORATE_ENDPOINT.employees}`,
        method: "GET",
        params: collectionQueryBuilder(collection),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            corporateEmployeeCollection = param;
          }
        } catch {
          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      },
    }),

    createCorporateEmployee: builder.mutation<Passenger, Passenger>({
      query: (newData: any) => ({
        url: `${CORPORATE_ENDPOINT.create}`,
        method: "post",
        data: newData,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully created");
            dispatch(
              corporateQuery.util.updateQueryData(
                "getCorporateEmployees",
                corporateEmployeeCollection,
                (draft) => {
                  if (data) {
                    draft.data.push(data);
                    draft.count += 1;
                  }
                }
              )
            );
          }
        } catch {
          notification("error", "Unable to create");
          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      },
    }),

    updateCorporate: builder.mutation<Passenger, Passenger>({
      query: (newData: any) => ({
        url: `${CORPORATE_ENDPOINT.create}`,
        method: "put",
        data: newData,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              corporateQuery.util.updateQueryData(
                "getCorporateEmployees",
                corporateEmployeeCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((employee) => {
                      if (employee.id === data.id) return data;
                      else {
                        return employee;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch {
          notification("error", "Unable to update");
          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      },
    }),

    activateCorporate: builder.mutation<Corporate, string>({
      query: (id: string) => ({
        url: `${CORPORATE_ENDPOINT.create}/${id}`,
        method: "post",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated status");
          }
        } catch {
          notification("error", "Unable to update status");
          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      },
    }),

    archiveCorporate: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${CORPORATE_ENDPOINT.create}/${id}`,
        method: "delete",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully archived");
            dispatch(
              corporateQuery.util.updateQueryData(
                "getCorporateEmployees",
                corporateEmployeeCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter(
                      (corporate) => corporate.id !== id
                    );
                    draft.count -= 1;
                  }
                }
              )
            );
          }
        } catch {
          notification("error", "Unable to archive");
          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      },
    }),

    removeEmployee: builder.mutation<
      Passenger,
      { corporateId: string; id: string }
    >({
      query: (data) => ({
        url: `${CORPORATE_ENDPOINT.removeEmployee}`,
        method: "delete",
        data: data,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              corporateQuery.util.updateQueryData(
                "getCorporateEmployees",
                corporateEmployeeCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter(
                      (employee) => employee.id !== data.id
                    );
                    draft.count -= 1;
                  }
                }
              )
            );
            notification("success", "Successfully removed");
          }
        } catch {
          notification("error", "Unable to remove");
          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      },
    }),

    importEmployee: builder.mutation<any, any>({
      query: (excelData: any) => ({
        url: `${CORPORATE_ENDPOINT.import_corporate_employee}`,
        method: "post",
        data: excelData,
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              corporateQuery.util.updateQueryData(
                "getCorporateEmployees",
                corporateEmployeeCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.concat(data?.data);
                    draft.count += data?.data?.length;
                  }
                }
              )
            );
            notification("success", "Successfully imported");
          }
        } catch {
          notification("error", "Unable to remove");
          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      },
    }),

    getTransactions: builder.query<Collection<Transaction>, CollectionQuery>({
      query: (collection) => ({
        url: `${CORPORATE_ENDPOINT.transactions}`,
        method: "GET",
        params: collectionQueryBuilder(collection),
      }),
    }),
    getTransactionStatus: builder.query<any, void>({
      query: (collection) => ({
        url: `${CORPORATE_ENDPOINT.transaction_status}`,
        method: "GET",
      }),
    }),
    getCorporate:builder.query<Corporate,string>({
      query:(id:string)=>({
        url:`${CORPORATE_ENDPOINT.corporate}/${id}`,
        method:'GET'
      })
    }),
    getWallet: builder.query<Wallet, void>({
      query: () => ({
        url: `${CORPORATE_ENDPOINT.wallet}`,
        method: "GET",
      }),
    }),
    getTotalCorporateDeposit: builder.query<any, void>({
      query: () => ({
        url: `${CORPORATE_ENDPOINT.total_corporate_deposit}`,
        method: "GET",
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetCorporateEmployeesQuery,
  useCreateCorporateEmployeeMutation,
  useRemoveEmployeeMutation,
  useArchiveCorporateMutation,
  useUpdateCorporateMutation,
  useActivateCorporateMutation,
  useImportEmployeeMutation,
  useLazyGetTransactionsQuery,
  useLazyGetWalletQuery,
  useLazyGetTotalCorporateDepositQuery,
  useLazyGetCorporateQuery,
  useLazyGetTransactionStatusQuery
} = corporateQuery;

/* eslint-disable @typescript-eslint/no-explicit-any */

import { Collection, CollectionQuery } from "../../../models/collection.model";
import { Service } from "../../../models/service.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { HOME_ENDPOINT } from "../home.endpoint";

let currentCollection: CollectionQuery;

const employeeQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getEmployees: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${HOME_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          currentCollection = param;
        } catch {
          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      },
    }),
    getEmployee: builder.query<any, string>({
      query: (id: string) => ({
        url: `${HOME_ENDPOINT.employee}/${id}`,
        method: "GET",
      }),
    }),
    createEmployee: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${HOME_ENDPOINT.create}`,
        method: "POST",
        data: data,
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully created");
          dispatch(
            employeeQuery.util.updateQueryData(
              "getEmployees",
              currentCollection,
              (draft) => {
                draft.data.unshift(data);
                draft.count = draft.count + 1;
              }
            )
          );
        } catch {
          notification("error", "Error try again");
          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      },
    }),
    deleteEmployee: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${HOME_ENDPOINT.delete}?id=${id}`,
        method: "DELETE",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully deleted");
          dispatch(
            employeeQuery.util.updateQueryData(
              "getEmployees",
              currentCollection,
              (draft) => {
                draft.data = draft.data.filter(
                  (employee:any) => employee.id !== id
                );
                draft.total -= 1;
              }
            )
          );
        } catch {
          notification("error", "Error try again");
          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      },
    }),
    updateEmployee: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${HOME_ENDPOINT.update}`,
        method: "PUT",
        data: data,
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully updated");
          dispatch(
            employeeQuery.util.updateQueryData(
              "getEmployees",
              currentCollection,
              (draft) => {
                draft.data = draft?.data?.map((employee:any) => {
                  if (employee.id === data.id) return data;
                  else {
                    return employee;
                  }
                });
              }
            )
          );
        } catch {
          notification("error", "Error try again");
          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      },
    }),
    getService: builder.query<Collection<Service>, CollectionQuery>({
      query: (data:CollectionQuery) => ({
        url: `${HOME_ENDPOINT.services}`,
        method: "GET",
        params:collectionQueryBuilder(data)
      }),
    }),
  }),
  overrideExisting: true,
});
export const {
useLazyGetEmployeesQuery,
  useCreateEmployeeMutation,
  useDeleteEmployeeMutation,
  useUpdateEmployeeMutation,
  useLazyGetEmployeeQuery,
  useLazyGetServiceQuery
} = employeeQuery;

import { RouteObject } from "react-router-dom";
import ChangePasswordPage from "../page/change-password";
import ForgotPasswordPage from "../page/forgot-password-page";
import UpdatePasswordPage from "../page/update-password";

export const AccountRoute: RouteObject = {
  path: "/accounts/forget-password",
  element: (
      <ForgotPasswordPage />
  ),
};
export const ChangePasswordRoute: RouteObject = {
  path: "/accounts/change-password",
  element: (
      <ChangePasswordPage />
  ),
};
export const UpdatePasswordRoute: RouteObject = {
  path: "/accounts/update-password",
  element: (
      <UpdatePasswordPage />
  ),
};

//token=d0c19dbc3ad827b4410a24b158a6dd98396346956597446725021ef271a5185a&id=7cb54c92-ca9e-450a-93c6-bf7473ff5c75
import { LoadingOverlay } from "@mantine/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Booking } from "../../../models/booking.model";
import { CollectionQuery } from "../../../models/collection.model";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import { Pagination } from "../../../shared/component/pagination/pagination";
import EmployeeBookingHistoryCard from "../component/employee-booking-history-card";
import { useLazyGetEmployeeBookingHistoriesQuery } from "../store/payment.query";

export default function EmployeeBookingHistory() {
  const params = useParams();
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 6,
    filter: [
      [{ field: "walletType", operator: "=", value: "CorporateWallet" }],
      [{ field: "status", operator: "in", value: ["Boarded", "Arrived"] }],
    ],
    orderBy:[{field:'createdAt',direction:'desc'}]
  });
  const [getBookingHistory, histories] =
    useLazyGetEmployeeBookingHistoriesQuery();

  useEffect(() => {
    getBookingHistory({ id: params.id, collection: collection });
  }, [params.id, collection]);
  return (
    <>
      <div className="w-full flex-col space-y-4 mt-2 p-2 border rounded-xl">
        <div className="flex p-2 items-center">
          <p className="font-semibold text-sm">Booking Histories</p>
        </div>
        {histories?.isLoading ||
          (histories?.data?.data?.length === 0 && (
            <div className="w-full relative flex items-center justify-center">
              <LoadingOverlay
                visible={histories?.isLoading || histories?.isFetching}
              />
              <EmptyIcon />
            </div>
          ))}
        {histories?.isSuccess &&
          histories?.data?.data?.length > 0 &&
          histories?.data?.data?.map((booking: Booking) => (
            <EmployeeBookingHistoryCard
              routeName={booking?.routeName}
              fee={booking?.fee}
              vehiclePlateNumber={booking?.vehiclePlateNumber}
              pickupName={booking?.pickupName}
              destinationName={booking?.destinationName}
              startingAt={booking?.startingAt}
              completedAt={booking?.completedAt}
              status={booking?.status}
            />
          ))}
        <EmployeeBookingHistoryCard
        routeName={"Ayer tena to merkato"}
        fee={245}
        vehiclePlateNumber={"A97568"}
        pickupName={"Ayer Tena"}
        destinationName={"Merkato"}
        startingAt={"2023-02-02T18:05:10.470Z"}
        completedAt={"2023-02-02T18:05:10.470Z"}
        status={"Pending"}
      />
      <EmployeeBookingHistoryCard
        routeName={"Ayer tena to merkato"}
        fee={245}
        vehiclePlateNumber={"A97568"}
        pickupName={"Ayer Tena"}
        destinationName={"Merkato"}
        startingAt={"2023-02-02T18:05:10.470Z"}
        completedAt={"2023-02-02T18:05:10.470Z"}
        status={"Completed"}
      />
      <EmployeeBookingHistoryCard
        routeName={"Ayer tena to merkato"}
        vehiclePlateNumber={"B39254"}
        fee={245}
        pickupName={"Ayer Tena"}
        destinationName={"Merkato"}
        startingAt={"2023-02-02T18:05:10.470Z"}
        completedAt={"2023-02-02T18:05:10.470Z"}
        status={"Cancelled"}
      />
      </div>
      {histories?.isSuccess && histories?.data?.data?.length > 0 && (
        <div className="w-full flex justify-end items-center mt-2 mb-2">
          <Pagination
            total={histories?.data ? histories?.data?.count : 0}
            defaultPageSize={6}
            pageSize={[6, 12, 18]}
            onPaginationChange={(skip: number, top: number) => {
              setCollection({ skip: skip, top: top, ...collection });
            }}
          />
        </div>
      )}
    </>
  );
}

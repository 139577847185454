import { RouteObject } from "react-router-dom";
import Deposit from "../page/deposit";
import EmployeeBookingHistory from "../page/employee-booking-history";
import PaymentForEmployee from "../page/payment-for-employee";

export const PaymentRoute: RouteObject = {
  path: "payment",
  element: <PaymentForEmployee />,
  children: [
    { path: "deposit", element: <Deposit /> },
    { path: "detail/:id", element: <EmployeeBookingHistory /> },
  ],
};

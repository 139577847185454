import { RouteObject } from "react-router-dom";
import HomePage from "../page/home-page";
export const HomeRoute: RouteObject = {
  path: "/",
  element: (
      <HomePage />
  ),
  index:true
 
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { User } from "../../../models/user.model";

import { userInfo } from "../api/auth.api";

const user: User = {
  id: "",
  email: "",
  name: "",
  enabled: false,
  phoneNumber: "",
  accountId: "",
  gender: "",
  type: "",
  profileImage: {
    filename: "",
    mimetype: "",
    originalname: "",
    size: 0,
    path:''
  },
  address: {
    country: "",
    city: "",
    subCity: "",
    woreda: "",
    houseNumber: "",
  },
  emergencyContact: {
    name: "",
    phoneNumber: ""
  },
  role: [""],
};

const AuthContext = React.createContext({
  user: user,
  authenticated: false,
  login: (account: any) => {},
  logOut: () => {},
});

export const AuthContextProvider = ({ children }: any) => {
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [user, setUserInfo] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    async function init() {
      if (await localStorage.userInfo !== undefined) {
        setAuthenticated(true);
        setUserInfo(JSON.parse(await localStorage.userInfo));
      }
    }
    init();
  }, []);
  async function Login(account: any) {
    const data = await userInfo(account);
    if (data?.id) {
      setAuthenticated(true);
      setUserInfo(data);
      navigate('/');
      
    }
  }
  async function logOut() {
    localStorage.removeItem("userInfo");
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    setAuthenticated(false);
  }

  const context = {
    user: user,
    authenticated: authenticated,
    login: Login,
    logOut: logOut,
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};
export default AuthContext;

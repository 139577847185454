import { RouteObject } from "react-router-dom";
import CorporateEmployeeCreateComponent from "../component/corporate-employee-create-component";
import AuthGuard from "../../../shared/auth/component/auth";
import CorporateDashboard from "../page/corporate-dashboard";
import { PaymentRoute } from "../../payment/route/payment.route";
import CorporateTransaction from "../page/corporate-transaction";
export const CorporateRoute: RouteObject = {
  path: "/corporate",
  element: (
    <AuthGuard role={["corporate"]}>
      <CorporateDashboard />
    </AuthGuard>
  ),
  children: [
    PaymentRoute,
    {
      path:'transactions',
      element:<CorporateTransaction/>
    }
  ],
};

import { Booking } from "../../../models/booking.model";
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { Passenger } from "../../../models/passenger.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { PAYMENT_ENDPOINT } from "../payment.endpoint";


let corporateEmployeeCollection:CollectionQuery;


const paymentQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getCorporateEmployees: builder.query<Collection<Passenger>, CollectionQuery >({
      query: (data) => ({
        url: `${PAYMENT_ENDPOINT.employee}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            corporateEmployeeCollection = param;
          }
        } catch {
          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      },
    }),

    getPassengers: builder.query<Collection<Passenger>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${PAYMENT_ENDPOINT.passengerList}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
    }),
    getPaymentUrl: builder.mutation<any, any>({
      query: (data: {
        amount: number;
        passengerId: string;
        returnUrl: string;
      }) => ({
        url: `${PAYMENT_ENDPOINT.pay_with_chapa}`,
        method: "POST",
        data: data,
      }),
    }),
    initiateTelebirr: builder.mutation<any, any>({
      query: (data:{returnUrl:string,amount:number}) => ({
        url: `http://196.189.119.68:8000/api/corporate-transactions/initiate-telebirr`,
        method: "POST",
        data:data}),
    }),
    depositWithChapa: builder.mutation<any, any>({
      query: (data: { amount: number; returnUrl: string }) => ({
        url: `${PAYMENT_ENDPOINT.deposit_with_chapa}`,
        method: "POST",
        data: data,
      }),
    }),
    getMyWallet: builder.query<any, void>({
      query: () => ({
        url: `${PAYMENT_ENDPOINT.get_my_wallet}`,
        method: "GET",
      }),
    }),
    getEmployeeBookingHistories: builder.query<Collection<Booking>, any>({
      query: (data:{id:string,collection:CollectionQuery}) => ({
        url: `${PAYMENT_ENDPOINT.employee_booking_history}/${data?.id}`,
        method: "GET",
        params:collectionQueryBuilder(data?.collection)
      }),
    }),
    transferToMultipleEmployees: builder.mutation<any, any>({
      query: (data: { amount: number; employees: string[] }) => ({
        url: `${PAYMENT_ENDPOINT.transfer_to_multiple_employees}`,
        method: "POST",
        data: data,
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          notification("success", "Successfully Transferred");
          dispatch(
            paymentQuery.util.updateQueryData('getMyWallet',undefined, (draft) => {
              draft.balance = data.balance;
            })
          );
        } catch(error:any) {
          notification("error", error?.error?.data?.message);
          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetPassengersQuery,
  useInitiateTelebirrMutation,
  useLazyGetCorporateEmployeesQuery,
  useGetPaymentUrlMutation,
  useDepositWithChapaMutation,
  useLazyGetMyWalletQuery,
  useTransferToMultipleEmployeesMutation,
  useLazyGetEmployeeBookingHistoriesQuery
} = paymentQuery;

export const CORPORATE_ENDPOINT={
    create:`${process.env.REACT_APP_API}/passengers/create-corporate-passenger`,
    employees:`${process.env.REACT_APP_API}/passengers/get-our-corporate-customers`,
    employee:`${process.env.REACT_APP_API}/passengers/get-passenger`,
    removeEmployee:`${process.env.REACT_APP_API}/passengers/remove-employee-from-corporate`,
    addEmployee:`${process.env.REACT_APP_API}/passengers/add-employee-to-corporate`,
    registerEmployee:`${process.env.REACT_APP_API}/passengers/create-corporate-passenger`,
    passengerList:`${process.env.REACT_APP_API}/passengers/get-passengers`,
    pay_with_chapa:`${process.env.REACT_APP_API}/transactions/pay-with-chapa`,
    transactions:`${process.env.REACT_APP_API}/corporate-transactions/get-my-transactions`,
    wallet:`${process.env.REACT_APP_API}/corporate-wallets/get-my-wallet`,
    import_corporate_employee:`${process.env.REACT_APP_API}/passengers/import-our-employees`,
    total_corporate_deposit:`${process.env.REACT_APP_API}/corporate-transactions/get-total-corporate-deposits`,
    corporate:`${process.env.REACT_APP_API}/corporates/get-corporate`,
    transaction_status:`${process.env.REACT_APP_API}/corporate-transactions/group-corporate-transactions-by-status`,
 
}
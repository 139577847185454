import { Burger, Drawer, Image } from "@mantine/core";
import {
  IconKey,
  IconLogout,
  IconMenu,
  IconMenu2,
  IconUser,
} from "@tabler/icons";
import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import AuthContext from "../../shared/auth/context/authContext";

export default function MobileHeader() {
  const [opened, setOpened] = useState(false);
  const { logOut,  authenticated } = useContext(AuthContext);
  return (
    <>
      <div className="fixed w-full top-0 z-50 md:hidden bg-primary flex items-center justify-between px-2 h-14 text-white">
        <div>
          <Image src={"/kabba-logo.png"} width={80} height={30} />
        </div>
        <div className="flex items-center ">
          <Burger
            color="white"
            className="z-50"
            opened={opened}
            onClick={() => setOpened((o) => !o)}
          />
        </div>
      </div>
      <Drawer
        opened={opened}
        onClose={() => setOpened(false)}
        classNames={{
          title: "w-full p-0 mr-0",
          header: "p-0 w-full",
        }}
        withCloseButton={false}
        closeOnClickOutside
        lockScroll={false}
        title={<div className="h-24"></div>}
        padding="xl"
        size={"75%"}
      >
        <div className="h-screen relative bg-yellow-500 ">
          <div className="flex h-full">
            {!authenticated ? (
              <>
                {" "}
                <NavLink to={"/login"}>
                  <div className="px-2 h-full flex items-center text-white space-x-2">
                    <IconKey size={16} />
                    <span>Login</span>
                  </div>
                </NavLink>
                <NavLink to={"/register"}>
                  <div className="px-2 h-full flex items-center space-x-2">
                    <IconUser size={16} />
                    <span>Register</span>
                  </div>
                </NavLink>
              </>
            ) : (
              <div
                onClick={() => logOut()}
                className="px-2 h-full cursor-pointer flex items-end absolute  text-white  space-x-2"
              >
                <IconLogout size={16} />
                <span>Logout</span>
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
}
